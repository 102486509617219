<template>
  <span class="svg-icon"/>
</template>

<script>
const cache = new Map()

export default {
  props: {
    filepath: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    }
  },
  watch: {
    fill() {
      this.updateSVGStyle('fill', this.fill)
    },
    stroke() {
      this.updateSVGStyle('stroke', this.stroke)
    },
    width() {
      this.updateSVGStyle('width', this.width)
    },
    height() {
      this.updateSVGStyle('height', this.height)
    }
  },
  async mounted() {
    if (!cache.has(this.filepath)) {
      try {
        cache.set(this.filepath, fetch(this.filepath).then(r => r.text()))
      } catch{
        cache.delete(this.filepath)
      }
    }
    if (cache.has(this.filepath)) {
      this.$el.innerHTML = await cache.get(this.filepath)
      this.updateSVGStyle('fill', this.fill)
      this.updateSVGStyle('stroke', this.stroke)
      this.updateSVGStyle('width', this.width)
      this.updateSVGStyle('height', this.height)
    }
  },
  methods: {
    updateSVGStyle(property, value) {
      const svgElement = this.$el.getElementsByTagName('svg')[0]
      if (svgElement) {
        svgElement.style[property] = value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-block;
  line-height: 0;
}
</style>
